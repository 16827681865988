define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-badge", ["exports", "@ember/component", "@ember/service", "@ember/object", "@glimmer/component", "@glimmer/tracking", "I18n", "@ember/template-factory"], function (_exports, _component, _service, _object, _component2, _tracking, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @icon={{this.updateIcon}}
    @action={{this.update}}
    class="btn update"
    @disabled={{this.updating}}
    @title="admin.wizard.subscription.update.title"
  >
    {{#if this.updating}}
      {{loading-spinner size="small"}}
    {{/if}}
  </DButton>
  <DButton
    @action={{this.click}}
    class="wizard-subscription-badge {{this.subscription.subscriptionType}}"
    @title={{this.title}}
  >
    {{d-icon "pavilion-logo"}}
    <span>{{this.label}}</span>
  </DButton>
  */
  {
    "id": "dYgI3gNX",
    "block": "[[[8,[39,0],[[24,0,\"btn update\"]],[[\"@icon\",\"@action\",\"@disabled\",\"@title\"],[[30,0,[\"updateIcon\"]],[30,0,[\"update\"]],[30,0,[\"updating\"]],\"admin.wizard.subscription.update.title\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"updating\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"],[8,[39,0],[[16,0,[29,[\"wizard-subscription-badge \",[30,0,[\"subscription\",\"subscriptionType\"]]]]]],[[\"@action\",\"@title\"],[[30,0,[\"click\"]],[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,3],[\"pavilion-logo\"],null]],[1,\"\\n  \"],[10,1],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"d-button\",\"if\",\"loading-spinner\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-badge.hbs",
    "isStrictMode": false
  });
  let WizardSubscriptionBadge = _exports.default = (_dec = (0, _object.computed)("subscription.subscriptionType"), _dec2 = (0, _object.computed)("i18nKey"), _dec3 = (0, _object.computed)("i18nKey"), (_class = class WizardSubscriptionBadge extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "subscription", _descriptor, this);
      _initializerDefineProperty(this, "updating", _descriptor2, this);
      _initializerDefineProperty(this, "updateIcon", _descriptor3, this);
      _defineProperty(this, "basePath", "/admin/plugins/subscription-client");
    }
    get i18nKey() {
      return `admin.wizard.subscription.type.${this.subscription.subscriptionType ? this.subscription.subscriptionType : "none"}`;
    }
    get title() {
      return `${this.i18nKey}.title`;
    }
    get label() {
      return _I18n.default.t(`${this.i18nKey}.label`);
    }
    click() {
      window.open(this.subscription.subscriptionCtaLink, "_blank").focus();
    }
    update() {
      this.updating = true;
      this.updateIcon = null;
      this.subscription.updateSubscriptionStatus().finally(() => {
        this.updateIcon = "sync";
        this.updating = false;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "subscription", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updating", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "updateIcon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "sync";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "i18nKey", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "i18nKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "title", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "label", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WizardSubscriptionBadge);
});